@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  color: white;
  background-color: #07080D;
}

.navbar > * {
  padding-right: 32px;
}

.list-style > ul,
ol {
  list-style: disc;
}

.list-style > ul > li,
ol {
  margin-bottom: 10px;
}

.checkout-banner > .MuiCircularProgress-root {
  color: gray;
}
.pay-tab.Mui-selected {
  color: black !important;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.12);
}
.MuiTabs-indicator {
  display: none;
}
.MuiPopperUnstyled-root {
  z-index: 51;
}

[hidden] {
  display: none !important;
}

ul[data-mce-fragment="1"] {
  padding-left: 20px;
}


#madlad-nft-modal::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}

#madlad-nft-modal::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-border-radius: 7px;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

#madlad-nft-modal::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

.main-container {
  background-color: #010101 !important;
}

.custom-grid-center:nth-child(4n + 1) {grid-column:1}
.custom-grid-center:nth-child(4n + 2) {grid-column:2}
.custom-grid-center:nth-child(4n + 3) {grid-column:3}
.custom-grid-center:nth-child(4n + 4) {grid-column:4}
.custom-grid-center {
width: 64px;
height: 64px;
}

.custom-mobile-menu > .Mui-expanded {
  border-bottom-left-radius: 0!important;
  border-bottom-right-radius: 0!important;
}

.custom-mobile-menu > .MuiAccordion-root > .MuiCollapse-root {
  top: -20px;
  position: relative;
  padding-top: 20px;
  background-color: rgb(25, 30, 35);
  z-index: 1;
  border-bottom-left-radius: 21px;
  border-bottom-right-radius: 21px;
}

.custom-menu-itmes {
  border-top-left-radius: 0!important;
  border-top-right-radius: 0!important;
}
.custom-bg-border-div {
  background-image: url("/CTA-box.svg");
  box-shadow: 46px 46px 40px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(25px);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.custom-select > .MuiPaper-root.MuiMenu-paper {
  background-color: #191E23;
  margin-top: 4px;
}
.custom-select-input.Mui-focused {
  outline: 2px solid #818C99;
  outline-offset: 2px;
  background: #292F36;
}

.custom-select-input > svg.MuiSelect-icon {

  right: 12px !important;
  top: calc(41% - 0.5em) !important;

}

@media (min-width: 900px) {
  .rhc-banner__content__message {
    max-width: 50%;
    margin-right: 1rem;
  }
}

.bg-custom-gradient-1 {
  background: linear-gradient(180deg, #FCFCF7 -4.34%, #72E26F 39.21%, #D3BCFF 81.87%);
}
@media (min-width: 640px) {
  .bg-custom-gradient-1 {
    background: linear-gradient(90deg, #FCFCF7 -4.34%, #72E26F 39.21%, #D3BCFF 81.87%);
  }
}
